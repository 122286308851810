<template>
  <a-modal :footer="null" @cancel="closeModal" class="add-to-category-modal">
    <div class="add-to-category-modal-container">
      <div class="add-to-category-modal-container__header">
        <h1 class="add-to-category-modal-container__header--title">
          Add Connection to a Group
        </h1>
      </div>
      <div class="add-to-category-modal-container__body">
        <div class="user">
          <div class="user__profile-pic">
            <img :src="selectedConn.profile_pic" alt="" class="image" />
            <div class="mark">
              <i class="icon icon-tick-mark_circle"></i>
            </div>
          </div>
          <p class="user__name">{{ selectedConn.name }}</p>
        </div>
        <div class="category">
          <!-- <select
            v-model="selectedCategory"
            placeholder="Select a Group"
            style="width: 100%; height: 3.5rem;"
          >
            <option
              v-for="option in options"
              :key="option.value"
              :value="option.value"
              >{{ option.label }}</option
            >
          </select> -->
          <a-select
            v-model:value="selectedCategory"
            placeholder="Select a Group"
            :options="options"
          >
          </a-select>
          <p v-if="error" class="error">{{ error }}</p>
          <div class="category__add" @click="$emit('add-new-category')">
            <i class="icon icon-add_plus"></i>
            Add New Group
          </div>
        </div>
        <a-button :loading="loading" class="btn" @click="addToCategory"
          >Save</a-button
        >
      </div>
    </div>
  </a-modal>
</template>

<script>
export default {
  props: {
    selectedConn: Object,
    audienceGroups: Array,
  },
  emits: ["close-modal", "add-new-category"],
  data() {
    return {
      selectedCategory: null,
      options: [],
      messageDialog: false,
      message: {
        title: "",
        desc: "",
        type: "",
      },
      loading: false,
      error: "",
    };
  },
  mounted() {
    this.audienceGroups.map((val) => {
      let group = {
        value: val.id,
        label: val.group_name,
      };
      this.options.push(group);
    });
  },
  methods: {
    async addToCategory() {
      let requestObj = {
        group: this.selectedCategory,
        users: [this.selectedConn.id],
      };
      if (this.selectedCategory == null) {
        this.error = "Please select any category";
        return;
      }
      this.loading = true;
      try {
        await this.$store
          .dispatch("profile/addFriendToCategory", requestObj)
          .then(() => {
            this.message = {
              title:
                "Your connection has been successfully added to the group.",
              // desc: "Your connection has been successfully added to the group.",
              type: "success",
            };
            this.closeModal();
          });
      } catch {
        this.message = {
          title: "Something went wrong",
          desc: "Please Try Again later. Sorry for the inconvenience",
          type: "failure",
        };
        this.closeModal();
      }
    },
    closeModal() {
      this.loading = false;
      this.error = "";
      this.selectedCategory = null;
      this.$emit("close-modal", this.message);
    },
  },
};
</script>

<style lang="scss">
.add-to-category-modal {
  width: 50rem !important;
  .ant-modal-content {
    padding: 0 !important;
    .ant-modal-body {
      padding: 0 !important;
      .add-to-category-modal-container {
        padding: 2.5rem;
        border-radius: 1rem;
        background-color: #f6f8ff;
        &__header {
          margin-bottom: 2.5rem;
          &--title {
            font-size: 2.4rem;
            font-family: $font-primary-bold;
            line-height: normal;
            margin-bottom: 0;
          }
        }
        &__body {
          .user {
            display: flex;
            align-items: center;
            padding: 1rem;
            background-color: $color-white;
            width: 70%;
            margin: 0 auto 2rem;
            border-radius: 0.5rem;
            box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
            &__profile-pic {
              width: 4rem;
              height: 4rem;
              position: relative;
              .image {
                width: 100%;
                border-radius: 0.5rem;
              }
              .mark {
                width: 1.5rem;
                height: 1.5rem;
                background-color: $color-primary;
                border-radius: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                position: absolute;
                bottom: 1px;
                right: 1px;
                transform: translate(50%, 50%);
                .icon {
                  font-size: 1.2rem;
                  color: $color-white;
                }
              }
            }
            &__name {
              margin-left: 1.5rem;
              font-family: $font-primary-bold;
              font-size: 1.6rem;
              margin-bottom: 0;
            }
          }
          .category {
            .ant-select {
              width: 100% !important;
              height: 4rem !important;
              font-family: $font-primary;
              font-size: 1.6rem;
              .ant-select-selector {
                padding: 0 1.5rem;
                border: 1px solid #00000033;
                border-radius: 0.5rem !important;
                box-shadow: none;
                .ant-select-selection-search {
                  display: none !important;
                }
              }
            }
            &__add {
              font-family: $font-primary-medium;
              font-size: 1.6rem;
              margin-bottom: 0;
              cursor: pointer;
              color: $color-primary;
              width: fit-content;
              margin: 1rem 0 2rem;
              .icon {
                font-size: 1.5rem;
                margin-right: 0.5rem;
              }
            }
          }
          .ant-btn {
            border: none;
            font-size: 1.6rem;
            font-family: $font-primary-medium;
            cursor: pointer;
            background-color: $color-primary;
            color: $color-white;
            transition: all 0.2s;
            height: 4rem;
            width: 10rem;
            margin-left: auto;
            display: block;
            &:hover,
            &:active,
            &:focus {
              background-color: $color-primary;
              color: $color-white;
              border: none;
            }
          }
          .error {
            font-size: 1.4rem;
            font-family: $font-primary;
            color: red;
            margin-top: 0.5rem;
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
</style>
