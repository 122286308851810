<template>
  <div class="archive-management-sidebar">
    <div class="archive-management-sidebar__header">
      <div class="archive-management-sidebar__header--name">
        <h3>Archives</h3>
      </div>
    </div>
    <div class="archive-management-sidebar__body">
      <a-tabs
        v-model:activeKey="activeKey"
        class="archive-management-sidebar__body--tabs"
      >
        <a-tab-pane key="public">
          <template #tab>
            <div class="tab-name">
              <p>Public</p>
            </div>
          </template>
          <div>
            <button class="add-new-category-btn" @click="addNewCategory()" v-if="isLoggedInUser">
              <i class="icon icon-add_plus"></i>
              Add Categories Below
            </button>
            <div class="list">
              <template v-if="archiveFoldersLoader">
                <a-skeleton
                  v-for="(skeleton, index) in 5"
                  :key="index"
                  class="folder-skeleton"
                  active
                  :title="null"
                  :paragraph="{
                    rows: 1,
                    width: ['100%'],
                  }"
                />
              </template>
              <div class="message" v-if="!archiveFoldersLoader && archiveFolders.length === 0">
                <p>No categories found.</p>
              </div>
              <template v-if="!archiveFoldersLoader && archiveFolders.length > 0">
                  <a-tree 
                    :draggable="isLoggedInUser" 
                    block-node
                    :tree-data="archiveFolders"
                    @drop="onDrop"
                    v-model:expandedKeys="expandedKeys"
                    :autoExpandParent="true"
                  >
                    <template #title="{ title, parent, name, id, ...folder  }">
                        <span class="title" @click.stop="selectItem(folder.dataRef, id)">{{ title }}</span>
                        <a-dropdown
                          v-if="isLoggedInUser"
                          :trigger="['click']"
                          placement="bottomRight"
                        >
                          <a class="ant-dropdown-link">
                            <i class="icon icon-vertical_three-dots"></i>
                          </a>
                          <template #overlay>
                            <a-menu>
                              <a-menu-item v-if="!parent && isLoggedInUser" @click.prevent="addNewCategory(id)" key="0">
                                <a>Add Subcategory</a>
                              </a-menu-item>
                              <a-menu-item  @click.prevent="editFolder({name:name, id:id})" key="2">
                                <a>Edit Category</a>
                              </a-menu-item>
                              <a-menu-item @click.stop="showdeleteFolder({id})" key="3">
                                <a class="remove-btn">
                                  Remove Category
                                </a>
                              </a-menu-item>
                            </a-menu>
                          </template>
                        </a-dropdown>
                        <div v-if="isLoggedInUser" class="drag-btn">
                          <img src="@/assets/icons/drag-handle-icon.svg" alt="Drag" />
                        </div>
                    </template>
                  </a-tree>
              </template>
            </div>
          </div>
        </a-tab-pane>
        <a-tab-pane key="private" v-if="isLoggedInUser">
          <template #tab>
            <div class="tab-name">
              <p>Private</p>
            </div>
          </template>
          <div>
            <button class="add-new-category-btn" @click="addNewCategory()" v-if="isLoggedInUser">
              <i class="icon icon-add_plus"></i>
              Add Category Below
            </button>
            <div class="list">
              <template v-if="archiveFoldersLoader">
                <a-skeleton
                  v-for="(skeleton, index) in 5"
                  :key="index"
                  class="folder-skeleton"
                  active
                  :title="null"
                  :paragraph="{
                    rows: 1,
                    width: ['100%'],
                  }"
                />
              </template>
              <div class="message" v-if="!archiveFoldersLoader && archiveFolders.length <= 0">
                <p>No categories found.</p>
              </div>
              <template v-if="!archiveFoldersLoader && archiveFolders.length > 0">
                <a-tree 
                  draggable 
                  block-node
                  :tree-data="archiveFolders"
                  @drop="onDrop"
                  v-model:expandedKeys="expandedKeys"
                >
                  <template #title="{ title, parent, name, id, ...folder  }">
                      <span  class="title" @click.stop="selectItem(folder.dataRef, id)">{{ title }}</span>
                      <a-dropdown
                        v-if="isLoggedInUser"
                        :trigger="['click']"
                        placement="bottomRight"
                      >
                        <a class="ant-dropdown-link">
                          <i class="icon icon-vertical_three-dots"></i>
                        </a>
                        <template #overlay>
                          <a-menu>
                            <a-menu-item v-if="!parent" @click.prevent="addNewCategory(id)" key="0">
                              <a>Add Subcategory</a>
                            </a-menu-item>
                            <a-menu-item  @click.prevent="editFolder({name:name, id:id})" key="2">
                              <a>Edit Category</a>
                            </a-menu-item>
                            <a-menu-item @click.stop="showdeleteFolder({id})" key="3">
                              <a class="remove-btn">
                                Remove Category
                              </a>
                            </a-menu-item>
                          </a-menu>
                        </template>
                      </a-dropdown>
                      <div class="drag-btn">
                        <img src="@/assets/icons/drag-handle-icon.svg" alt="Drag" />
                      </div>
                  </template>
                  </a-tree>
              </template>
            </div>
          </div>
        </a-tab-pane>
      </a-tabs>
    </div>
  </div>
  <folder-modal
    :visible="visibleCategoryFolderModal"
    @handle-close="visibleCategoryFolderModal = false"
    :selectedFolderName="selectedFolderName"
    :selectedFolderId="folderId"
    :loading="loader"
    :isEditMode="isEditing"
    @save-record="handleSave"
  ></folder-modal>
  <message-toaster
    :visible="showMessageModal"
    :message="message"
    @handle-close="showMessageModal = false"
  >
  </message-toaster>
  <delete-modal
    :visible="showFolderDeleteModal"
    @handle-close="showFolderDeleteModal = false"
    title="Are you sure you want to remove this?"
    confirmText="Yes, Remove"
    cancelText="No, Don't Remove"
    :loading="loader"
    @delete-record="deleteFolder"
  ></delete-modal>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import archiveMixin from "../../../mixins/archive.mixin";
import FolderModal from "./FolderModal.vue";
import DeleteModal from "../../BaseComponents/DeleteModal.vue";
import MessageToaster from "../../BaseComponents/MessageToaster.vue";
// import Draggable from "vue3-draggable";


export default {
  mixins: [archiveMixin],
  data() {
    return {
      activeIndex: null,
      activeKey: "public",
      selectedFolderName: "",
      folderId: "",
      visibleCategoryFolderModal: false,
      showFolderDeleteModal: false,
      loader: false,
      isEditing: false,
      showMessageModal: false,
      message: {},
      categoryToBeUpdateOrder: '',
      parentId: '',
      expandedKeys: [],
      duplicateFolderList: []
    };
  },
  components: {
    FolderModal,
    DeleteModal,
    MessageToaster,
    // Draggable
  },
  computed: {
    ...mapGetters({
      archiveFolderList: "archive/archiveFolderList",
      archiveFoldersLoader: "archive/getArchiveFolderLoader",
      isLoggedInUser: 'profile/isLoggedinUser',
      selectedArchiveContentType: 'archive/selectedArchiveContentType',
      selectedUser: 'profile/selectedUser',
      archiveContentCurrentPage: 'archive/archiveContentCurrentPage',
    }),
    archiveFolders:{
      get() {
        const folderList = this.archiveFolderList.map(folder=>({
          key:folder.id,
          title: folder.name,
          children:folder.child ? folder.child.map(child=>({
            key:child.id,
            title:child.name,
            ...child
          })) : [],
          ...folder
        }))
        return folderList.sort((a,b)=>a.order-b.order)
      },
      async set(folders) {

        folders.map(folder=>folder.child)

        let orderFolder = null
        let orderFolderIndex = null

        folders.forEach((folder, index)=>{
          if (folder.id === this.categoryToBeUpdateOrder){
            orderFolder = {...folder}
            orderFolderIndex = index
          }
          else{
            folder.children.forEach((child, index, array)=>{
              if (child.id === this.categoryToBeUpdateOrder){
                  orderFolder = {...child}
                  orderFolderIndex = index
              }
            })
          }
        })
        try{
         
          if (orderFolder){
            await this.updateArhiveCategoriesOrdering({archiveFolderId: orderFolder?.id, order:orderFolderIndex +1})
            this.$store.commit('archive/SET_ARCHIVE_FOLDER_LIST', folders);
            this.showMessageModal = true
            this.message = {
              title: "Category position updated successfully.",
              type: "success",
            };
          }
        }
        catch(err){
          console.log(err)
        }
      }
    },
  },
  watch: {
    activeKey(val) {
      if (val === "public")
        this.fetchArchiveFoldersList({ visibility: "public", user_id: this.selectedUser.id });
      else if (val === "private")
        this.fetchArchiveFoldersList({
          visibility: "private",
          user_id: this.selectedUser.id,
        });
    },
    // By default, the sidebar will show the public folders and active the first one
    archiveFolders(folder) {
      if (folder.length > 0) {
        this.activeIndex = folder[0].id;
        this.setSelectedFolder(folder[0]);
        if(this.archiveContentCurrentPage > 1){
          //Reset the pagination whenever preselecting the first folder after switching between public and private tabs
          this.updateArchiveContentCurrentPage('move-first-page')  // updating pagination triggers the fetching of articles
        }
        else{
          // if pagination is already set to 1 it will not trigger the watch function of over current page thus fetching the content manaully
          this.getSelectedContentTypeContentList({
            archiveFolderId: folder[0].id,
            contentType: 'articles',
            userId: this.selectedUser.id,
          })
        }
      } else {
        this.activeIndex = null;
        this.emptyArchiveContentList()
        this.emptySelectedArchiveFolder()
      }

      this.expandedKeys = folder.map(folder=>folder.key)
    },
  },
  created(){
    this.emptyArchiveContentList()
    this.emptySelectedArchiveFolder()
  },
  methods: {
    ...mapActions({
      fetchArchiveFoldersList: "archive/fetchArchiveFoldersList",
      archiveFolderCreate: "archive/archiveFolderCreate",
      saveArticleToArchiveFolder: "archive/saveArticleToArchiveFolder",
      setSelectedFolder: "archive/setSelectedFolder",
      emptyArchiveContentList: 'archive/emptyArchiveContentList',
      emptySelectedArchiveFolder :'archive/emptySelectedArchiveFolder',
      updateArchiveContentCurrentPage: 'archive/updateArchiveContentCurrentPage',
      updateArhiveCategoriesOrdering: 'archive/updateArhiveCategoriesOrdering'
    }),
    selectItem(folder, index) {
      this.activeIndex = index;
      this.setSelectedFolder(folder);
      if(this.archiveContentCurrentPage > 1){
          //Reset the pagination whenever switching between folders
          this.updateArchiveContentCurrentPage('move-first-page')  // updating pagination triggers the fetching of articles
      }
      else{
        if ( this.selectedArchiveContentType !== 'suggested'){
          this.getSelectedContentTypeContentList({
            archiveFolderId: folder.id,
            contentType: 'articles',
            userId: this.selectedUser.id,
          })
        }else{
          this.getSelectedContentTypeContentList({
                contentType: this.selectedArchiveContentType,
                searchTopic: folder?.name,
            })
        }
      }
    },
    addNewCategory(parentId='') {
      this.selectedFolderName = "";
      this.folderId = "";
      this.isEditing = false;
      this.visibleCategoryFolderModal = true;
      this.parentId=parentId
    },
    editFolder(folder) {
      this.selectedFolderName = folder.name;
      this.folderId = folder.id;
      this.isEditing = true;
      this.visibleCategoryFolderModal = true;
    },
    async handleSave(payload) {
      try {
        this.loader = true;
        const requestPayload = {
          name: payload.folderName,
          id: payload.id || null,
        };
        if (this.isEditing) {
          const response = await this.$store.dispatch(
            "archive/updateArchiveFolderName",
            requestPayload
          );
          if (response.status === 200) {
            this.visibleCategoryFolderModal = false;
            this.showMessageModal = true;
            this.loader = false;
            this.message = {
              title: "Category Edit Successfully",
              type: "success",
            };
          }
        } else {
          try {
            this.loader = true;
            const requestPayload = {
              name: payload.folderName,
              visibility: this.activeKey,
            };

            if(this.parentId){
              requestPayload.parent = this.parentId
            }

            await this.archiveFolderCreate(requestPayload)
            this.visibleCategoryFolderModal = false;
            this.showMessageModal = true;
            this.loader = false;
            this.message = {
              title: "Category Add Successfully",
              type: "success",
            };
          } catch (err) {
            console.log(err);
            this.showMessageModal = true;
            this.loader = false;
            this.message = {
              title: "Category Add Failed",
              type: "failure",
            };
            return;
          }
        }
      } catch (err) {
        console.log(err);
        this.loader = false;
      }
     
    },
    showdeleteFolder(folder) {
      this.folderId = folder.id;
      this.showFolderDeleteModal = true;
    },
    async deleteFolder() {
      try {
        this.loader = true;
        const response = await this.$store.dispatch(
          "archive/deleteArchiveFolder",
          this.folderId
        );
        if (response.status === 204) {
          this.showFolderDeleteModal = false;
          this.loader = false;
          this.showMessageModal = true;
          this.message = {
            title: "Category Deleted Successfully",
            type: "success",
          };
        }
      } catch (err) {
        console.log(err);
        this.showMessageModal = true;
        this.loader = false
        this.message = {
          title: "Failed to Delete Category",
          type: "failure",
        };
      }
    },
    onUpdate(newOrder){
      this.categoryToBeUpdateOrder = newOrder.id;
    },
    onDrop (info) {
      console.log(info)
      const dropKey = info.node.eventKey
      const dragKey = info.dragNode.eventKey
      const dropPos = info.node.pos.split('-')
      const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1])
      let isValid = true
      console.log(dropKey, dragKey, dropPos, dropPosition)
      // const dragNodesKeys = info.dragNodesKeys;
      const loop = (data, key, callback) => {
        data.forEach((item, index, arr) => {
          if (item.key === key) {
            return callback(item, index, arr)
          }
        if (item.children) {
          return loop(item.children, key, callback);
        }
        })
      }
      let data = JSON.parse(JSON.stringify(this.archiveFolders))
      let dragObj = ''
         loop(data, dragKey, (item, index, arr) => {
          if (info.dropToGap) {
            arr.splice(index, 1)
            dragObj = item
          }
        })
        
    if (info.dropToGap) {
      let ar
      let i
      loop(data, dropKey, (item, index, arr) => {
        ar = arr
        i = index
      })

      if (dropPosition === -1) {
        ar.splice(i, 0, dragObj)
      } else {
        if((!dragObj?.parent && !ar.every(folder=>folder?.parent)) || (dragObj?.parent && ar.every(folder=>folder?.parent === dragObj?.parent))){
          ar.splice(i + 1, 0, dragObj)
        }
      }

      data = data.map((data, index)=>({...data, order:index+1, children: data.children.map(folder=>({...folder, order:index+1})) }))

      // checking dragged folder validity
      data.forEach((folder, index, array)=>{
        folder.children.forEach((child, index, array)=>{
          if (child.id === dragKey){
          if (!child?.parent){
            isValid = false
            this.showMessageModal = true
            this.message = {
              title: "Can not move a category to another category.",
              type: "error",
            };
          }
          else if (child?.parent && !array.every(existingChild=>existingChild.parent === child.parent)){
            isValid = false
            this.showMessageModal = true
            this.message = {
              title: "Can not move a category to another category.",
              type: "error",
            };
          }
          else{
            isValid = true
          }
        }
        })
      })

      if (isValid){
        this.categoryToBeUpdateOrder = dragKey
        this.archiveFolders = [...data]
      }
    }
    },
  }
};
</script>

<style lang="scss">
.archive-management-sidebar {
  height: 100%;
  position: relative;
  margin-bottom: -1px;
  border-right: 1px solid $color-dark-grey-5;
  margin-right: 1.3rem;
  @include respond(phone-x-small) {
    border-right: 0;
  }
  &__header {
    padding: 0.5rem 2.6rem 0.4rem;
    display: flex;
    align-items: center;
    margin-bottom: 4rem;
    &--name {
      display: flex;
      align-items: center;
      width: 100%;
      h3 {
        font-size: 2rem;
        font-family: $font-primary-bold;
        margin-bottom: 0;
        padding: 0;
        line-height: 2.3rem;
        @include respond(tab-port) {
          font-size: 2.2rem;
          line-height: 2.8rem;
        }
      }
      .close-btn {
        display: none;
        margin-left: auto;
        width: fit-content;
        height: fit-content;
        background-color: transparent;
        line-height: normal;
        border: none;
        outline: none;
        cursor: pointer;
        .icon {
          transform: rotate(45deg);
          display: block;
          font-size: 2.5rem;
        }
        @include respond(tab-port) {
          display: block;
        }
      }
    }
  }
  &__body {
    .ant-tabs {
      .ant-tabs-bar {
        border-bottom: 1px solid $color-dark-grey-5;
        margin: 0;
        margin-bottom: 2rem;

        .ant-tabs-nav-container {
          padding: 0 0 0 2.6rem;

          .ant-tabs-tab-prev,
          .ant-tabs-tab-next {
            display: none !important;
          }

          .ant-tabs-nav-wrap {
            .ant-tabs-nav-scroll {
              overflow-x: auto;

              @include respond(phone-x-small) {
                &::-webkit-scrollbar {
                  display: none !important;
                }
              }
              &::-webkit-scrollbar {
                height: 0.5rem;
                width: 0.5rem;
              }

              &::-webkit-scrollbar-track {
                background-color: transparent;
                border-radius: 0.8rem;
                height: 0.5rem;
                width: 0.5rem;
              }

              &::-webkit-scrollbar-thumb {
                background-color: $color-primary;
                border-radius: 0.8rem;
              }

              .ant-tabs-nav {
                width: 100%;
                transform: none !important;
                .ant-tabs-tab {
                  background-color: $color-light-grey-3;
                  padding: 0.8rem 2rem;
                  border-radius: 100px;
                  margin-right: 1.6rem;
                  border-top-right-radius: 1rem !important;
                  border-top-left-radius: 1rem !important;
                  border-bottom-left-radius: 0 !important;
                  border-bottom-right-radius: 0 !important;
                  height: 4.1rem;
                  margin-bottom: -0.1rem;
                  display: flex;
                  align-items: center;
                  .tab-name {
                    display: flex;
                    align-items: center;

                    p {
                      font-size: 1.6rem;
                      line-height: 2;
                      font-family: $font-primary-medium;
                      color: $color-dark-grey-6;
                      margin: 0;
                    }

                    svg {
                      margin-right: 1rem;
                      width: 1.5rem;
                    }

                    svg g path {
                      fill: $color-primary;
                    }
                    .dropdown {
                      margin-left: 0.5rem;
                      .icon {
                        font-size: 1.2rem;
                        color: $color-dark-grey-6;
                      }
                    }
                  }

                  &:last-child {
                    margin-right: 0;
                  }
                }

                .ant-tabs-tab-active {
                  color: $color-white;
                  background-color: $color-primary;
                  font-family: $font-primary-medium;
                  text-shadow: none;

                  .tab-name {
                    p {
                      color: $color-white;
                    }

                    svg g path,
                    svg path {
                      fill: $color-white;
                    }

                    .dropdown {
                      .icon {
                        color: $color-white;
                      }
                    }
                  }
                }

                .ant-tabs-ink-bar {
                  display: none !important;
                }
              }
              .ant-tabs-nav > div {
                display: flex;
              }
            }
          }
        }
      }

      .ant-tabs-top-content {
        .ant-tabs-tabpane {
          height: max-content;
          overflow: hidden;
          padding: 0;
          .add-new-category-btn {
            background-color: $color-light-blue;
            border: none;
            outline: none;
            width: calc(100% - 4.2rem);
            font-size: 1.4rem;
            font-family: $font-primary-medium;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0 2rem;
            height: 4rem;
            border-radius: 100px !important;
            cursor: pointer;
            position: sticky;
            margin: 0 auto 3.4rem;
            top: 0;
            .icon {
              color: $color-primary;
              font-size: 1.3rem;
              margin-right: 0.9rem;
            }
          }
          .list {
            margin: 0 2.1rem 4rem 2.6rem;
            position: relative;
            height: 100%;
            // overflow-y: auto;
            overflow: hidden;
            // max-height: 44rem;
            // &::-webkit-scrollbar {
            //   height: 0.3rem;
            //   width: 0.3rem;
            // }
  
            // &::-webkit-scrollbar-track {
            //   background-color: transparent;
            //   border-radius: 0.8rem;
            //   height: 0.3rem;
            //   width: 0.3rem;
            // }
  
            // &::-webkit-scrollbar-thumb {
            //   background-color: $color-primary;
            //   border-radius: 0.8rem;
            // }
            .ant-tree > li:last-child {
              margin-bottom: 0 !important;
            }

            .ant-tree {
              height: 100%;
              overflow: hidden;
              li {
                margin-bottom: 1.2rem !important;
              }
              .ant-tree-treenode-switcher-open,
              .ant-tree-treenode-switcher-close {
                padding: 1.2rem 1.4rem 0.2rem;
                background-color: #F5F5F5;
                border-radius: 0.8rem;
                margin-bottom: 0;
                .ant-tree-switcher {
                  width: 2rem;
                  height: 2rem;
                  margin-top: 1px;

                  .anticon {
                    font-size: 1.6rem !important;
                  }
                }

                .ant-tree-node-content-wrapper {
                  padding: 0;
                  margin-bottom: 1rem;
                  padding: 0 1rem;
                  display: inline-flex;

                  &:hover {
                    background-color: rgb(217, 217, 217);
                  }

                  .ant-tree-icon__customize {
                    width: 2.1rem;
                    height: 2.1rem;
                    margin: 0 1.2rem 0 0.8rem;

                    img {
                      width: 100%;
                      height: 100%;
                      margin-bottom: 0.4rem;
                    }
                  }

                  .ant-tree-title {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    .title {
                      font-size: 1.4rem;
                      font-family: $font-primary-medium;
                      line-height: 1.8rem;
                      margin-top: 0.1rem;
                      color: $color-black;
                      width: calc(100% - 4.5rem);
                      white-space: nowrap;
                      overflow: hidden;
                      text-overflow: ellipsis;
                    }

                    .add-folder-btn {
                      width: fit-content;
                      height: fit-content;
                      margin-left: 1rem;
                      line-height: normal;
                      border: none;
                      outline: none;
                      cursor: pointer;
                      background-color: transparent;
                      vertical-align: middle;
                      margin-bottom: 2px;

                      .icon {
                        font-size: 1.2rem;
                      }
                    }
                    .ant-dropdown-link {
                      display: inline-block;
                      color: $color-black;
                      margin-left: auto;
                      height: fit-content;
                      width: fit-content;
                      line-height: normal;
                      .icon {
                        font-size: 1.3rem;
                      }
                    }
                    .drag-btn {
                      display: flex;
                      flex-direction: column;
                      border: none;
                      outline: none;
                      margin-left: 1.3rem;
                      height: 1.4rem;
                      width: 1.4rem;
                      justify-content: center;
                      align-items: center;
                      background-color: transparent;
                      cursor: move;
                      cursor: grab;
                      cursor: -moz-grab;
                      cursor: -webkit-grab;
                      img {
                        widows: 100%;
                      }
                    }
                  }
                }

                .ant-tree-node-selected {
                  background-color: transparent;
                }
                .ant-tree-child-tree {
                  li {
                    padding: 0;
                    margin-bottom: 0 !important;
                  }
                }
              }
            }
            
            
            .folder-skeleton {
              margin-bottom: 1.1rem;
              .ant-skeleton-content {
                .ant-skeleton-paragraph {
                  margin: 0;
                  li {
                    height: 5rem;
                  }
                }
              }
            }
            .message {
              padding-top: 4rem;
              p {
                font-size: 1.6rem;
                font-family: $font-primary;
                margin-bottom: 0;
                line-height: 2.3rem;
                color: $color-black;
                text-align: center;
              }
            }
          }
        }
      }
    }
  }
}
</style>
