<template>
  <a-modal
    width="55rem"
    @cancel="cancelEducationModal"
    class="education-modal-main"
  >
    <div class="education-modal">
      <h1 class="education-modal__heading">Education</h1>
      <div class="education-modal__form-fields">
        <a-form
          class="modal-form"
          ref="formRef"
          :label-col="{ style: { width: '17rem' } }"
        >
          
          <a-form-item label-align="left" label="School">
            <a-input
              placeholder="Ex: Boston University"
              v-model:value="issuing_organization"
            />
            <p v-if="error.issuing_organization" class="error">
              {{ error.issuing_organization }}
            </p>
          </a-form-item>
          <a-form-item label-align="left" label="Degree">
            <a-input placeholder="Ex Bachelor's of Science" v-model:value="title" />
            <p v-if="error.title" class="error">{{ error.title }}</p>
          </a-form-item>
          <a-form-item label-align="left" label="Field of Study">
            <a-input placeholder="Ex: Computer Science" v-model:value="field_of_study" />
            <p v-if="error.title" class="error">{{ error.title }}</p>
          </a-form-item>
          <a-form-item label-align="left" label="Start Date">
            <a-date-picker
              v-model:value="issue_date"
              picker="month"
              placeholder="Start Date"
              style="width: 100%"
              format="MMM YYYY"
              :disabled-date="disabledStartDate"
            />
            <p v-if="error.issue_date" class="error">{{ error.issue_date }}</p>
          </a-form-item>
          <a-form-item
            label-align="left"
            label="End Date"
            style="text-align: left"
          >
            <a-date-picker
              v-model:value="expire_date"
              picker="month"
              placeholder="End Date"
              style="width: 100%"
              format="MMM YYYY"
              :disabled="does_not_expire"
              :disabled-date="disabledEndDate"
            />
            <a-checkbox v-model:checked="does_not_expire" class="certificate-check">
              Present
            </a-checkbox>
            <p v-if="error.expire_date" class="error">
              {{ error.expire_date }}
            </p>
          </a-form-item>
          <!-- <a-form-item label-align="left" label="Credential Id">
            <a-input
              placeholder="Enter credential id"
              v-model:value="credential_id"
            />
            <p v-if="error.credential_id" class="error">
              {{ error.credential_id }}
            </p>
          </a-form-item>
          <a-form-item label-align="left" label="Credential Url">
            <a-input
              placeholder="Enter credential url"
              v-model:value="credential_url"
            />
            <p v-if="error.credential_url" class="error">
              {{ error.credential_url }}
            </p>
          </a-form-item> -->
        </a-form>
        <a-button
          v-if="action == 'add'"
          :loading="loading"
          class="form-btn"
          @click="addCertificate"
        >
          Add
        </a-button>
        <a-button
          v-else
          :loading="loading"
          class="form-btn"
          @click="updateCertificate"
        >
          Update
        </a-button>
      </div>
    </div>
    <message-toaster
      :visible="showMessageModal"
      :message="message"
      @handle-close="showMessageModal = false"
    >
    </message-toaster>
  </a-modal>
</template>
<script>
import MessageToaster from "../BaseComponents/MessageToaster.vue";

export default {
  props: {
    action: {
      type: String,
      required: true,
    },
    editCertificateDetails:{
      type: Object,
      default: () => ({})
    }
  },
  components: {
    MessageToaster,
  },
  emits: ["close-modal"],
  data() {
    return {
      id: null,
      title: null,
      issuing_organization: null,
      issue_date: null,
      expire_date: null,
      does_not_expire: true,
      credential_id: null,
      credential_url: null,
      field_of_study:null,
      error: {
        title: "",
        issuing_organization: "",
        issue_date: "",
        expire_date: "",
        does_not_expire: "",
        credential_id: "",
        credential_url: "",
        field_of_study: "",
      },
      loading: false,
      message: {
        title: "",
        desc: "",
      },
      showMessageModal: false,
    };
  },
  watch:{
    editCertificateDetails:{
      handler(details){
        if (Object.values(details).some(value=>value)){
          this.id= details.id
          this.title= details.title
          this.issuing_organization= details.issuing_organization
          this.issue_date= details.issue_date
          this.expire_date= details.expire_date
          this.does_not_expire= details.does_not_expire
          this.credential_id= details.credential_id
          this.credential_url= details.credential_url
          this.field_of_study = details?.field_of_study
        } else {
          this.id = null
          this.title= null
          this.issuing_organization= null
          this.issue_date= null
          this.expire_date= null
          this.does_not_expire= null
          this.credential_id= null
          this.credential_url= null
          this.field_of_study = null
        }
      },
      immediate: true
    }
  },
  methods: {
    selectCertificate(certificate) {
      this.id = certificate.id;
      this.title = certificate.title;
      this.issuing_organization = certificate.issuing_organization;
      this.issue_date = certificate.issue_date;
      this.expire_date = certificate.expire_date;
      this.does_not_expire = certificate.does_not_expire;
      this.credential_id = certificate.credential_id;
      this.credential_url = certificate.credential_url;
      this.field_of_study = certificate.field_of_study
    },
    cancelEducationModal() {
      this.resetErrors();
      this.resetFields();
      this.$emit("close-modal");
    },
    closeshowMessageModal() {
      this.showMessageModal = false;
      this.$emit("close-modal");
    },
    async addCertificate() {
      let issueDate = null;
      if (this.issue_date == null) {
        this.error.issue_date = "This field is required";
        this.error.title = this.title ? "" : "This field is required";
        this.error.issuing_organization = this.issuing_organization
          ? ""
          : "This field is required";
        // this.error.credential_id = this.credential_id
        //   ? ""
        //   : "This field is required";
        this.error.expire_date =
          this.does_not_expire || this.expire_date
            ? ""
            : "This field is required";
        return;
      } else {
        issueDate = this.issue_date;
      }
      issueDate = this.setDateFormat(issueDate);
      let expiryDate = this.does_not_expire ? null : this.expire_date;
      expiryDate = this.setDateFormat(expiryDate);
      let requestObj = {
        title: this.title,
        issuing_organization: this.issuing_organization,
        issue_date: issueDate,
        expire_date: expiryDate,
        does_not_expire: this.does_not_expire ? true : false,
        credential_id: this.credential_id,
        credential_url: this.credential_url,
        field_of_study: this.field_of_study
      };
      this.resetErrors();
      this.loading = true;
      try {
        const response = await this.$store.dispatch(
          "profile/addCertificate",
          requestObj
        );
        if (response.status === 201) {
          this.message = {
            title: "Education Added has been added successfully",
            // desc: "Your Details has been added successfully. Thanks for using Glocal.",
            type: "success",
          };
          this.resetFields();
          this.showMessageModal = true;
          this.$emit("close-modal");
        }
      } catch (err) {
        if (err.response.status === 400) {
          Object.keys(err.response.data).forEach((key, i, arr) => {
            err.response.data[key].forEach((val) => {
              this.error[key] += val + "\n";
            });
          });
        } else {
          this.message = {
            title: "Something went wrong",
            desc: "Please Try Again later. Sorry for the inconvenience",
            type: "failure",
          };
          this.showMessageModal = true;
        }
      }
      this.loading = false;
    },
    async updateCertificate() {
      this.expire_date = this.does_not_expire ? null : this.expire_date;
      let requestObj = {
        id: this.id,
        title: this.title,
        issuing_organization: this.issuing_organization,
        issue_date: this.setDateFormat(this.issue_date),
        expire_date: this.expire_date
          ? this.setDateFormat(this.expire_date)
          : null,
        does_not_expire: this.does_not_expire ? true : false,
        credential_id: this.credential_id,
        credential_url: this.credential_url,
        field_of_study: this.field_of_study
      };
      this.resetErrors();
      this.loading = true;
      try {
        const response = await this.$store.dispatch(
          "profile/updateCertificate",
          requestObj
        );
        if (response.status === 200) {
          this.message = {
            title: "Education Updated Successfully",
            desc: "",
            type: "success",
          };
          this.resetFields();
          this.showMessageModal = true;
          this.$emit("close-modal");
        }
      } catch (err) {
        if (err.response.status === 400) {
          Object.keys(err.response.data).forEach((key, i, arr) => {
            err.response.data[key].forEach((val) => {
              this.error[key] += val + "\n";
            });
          });
        } else {
          this.message = {
            title: "Something went wrong",
            desc: "Please Try Again later. Sorry for the inconvenience",
            type: "failure",
          };
          this.showMessageModal = true;
        }
      }
      this.loading = false;
    },
    resetErrors() {
      this.error = {
        title: "",
        issuing_organization: "",
        issue_date: "",
        expire_date: "",
        does_not_expire: "",
        credential_id: "",
        credential_url: "",
      };
    },
    resetFields() {
      this.id = null;
      this.title = null;
      this.issuing_organization = null;
      this.issue_date = null;
      this.expire_date = null;
      this.does_not_expire = true;
      this.credential_id = null;
      this.credential_url = null;
      this.loading = false;
    },
    setDateFormat(date) {
      date =
        typeof date == "object" && date !== null
          ? date._d.getFullYear() +
            "-" +
            (date._d.getMonth() + 1) +
            "-" +
            date._d.getDate()
          : date;
      return date;
    },
    disabledStartDate(startDate) {
      if (!startDate || !this.expire_date) {
        return false;
      }

      return startDate.valueOf() > this.expire_date.valueOf();
    },
    disabledEndDate(endDate) {
      if (!endDate || !this.issue_date) {
        return false;
      }

      return this.issue_date.valueOf() >= endDate.valueOf();
    },
    // handleStartDateChange(open) {
    //   if (!open) {
    //     this.endOpen = true;
    //   }
    // },
    // handleEndDateChange(open) {
    //   this.endOpen = open;
    // },
  },
};
</script>
<style lang="scss">
.education-modal-main {
  top: 8rem;
  @include respond(tab-port) {
    top: 5rem;
  }
  .ant-modal-content {
    position: relative;
    .ant-modal-close {
      margin-right: 3rem;
      margin-top: 3.4rem;
      height: fit-content;
      width: fit-content;
      .ant-modal-close-x {
        width: 2rem;
        height: 2rem;
        line-height: 2rem;
        margin-right: 0;
        margin-top: 0;
        .anticon-close {
          svg {
            width: 2rem;
          }
        }
      }
    }
    .ant-modal-body {
      .education-modal {
        padding: 3rem;
        background-color: #f6f8ff;
        border-radius: 1rem;
        &__heading {
          font-size: 2.2rem;
          font-family: $font-primary-bold;
          margin-bottom: 3rem;
          text-align: center;
          line-height: normal;
        }
        &__form-fields {
          .modal-form {
            .ant-form-item {
              margin-bottom: 2rem;
              @include respond(phone-x-small) {
                margin-bottom: 0;
              }
              .ant-form-item-label {
                padding-bottom: 0;
                label {
                  font-size: 1.6rem;
                  font-family: $font-primary;
                  height: auto;
                }
              }
              .ant-form-item-control {
                .ant-form-item-control-input {
                  .ant-form-item-control-input-content {
                    .ant-input {
                      padding: 0.4rem 1rem;
                      font-size: 1.6rem;
                      font-family: $font-primary;
                      margin-bottom: 0.5rem;
                    }
                    .error {
                      width: auto;
                      font-size: 1.4rem;
                      font-family: $font-primary;
                      color: red;
                      line-height: normal;
                    }
                    .certificate-check {
                      display: flex;
                      align-items: center;
                      margin-top: 0.5rem;
                      font-size: 1.6rem;
                    }
                    .ant-select {
                      text-align: left;
                    }
                    .description-textarea {
                      resize: none;
                    }
                  }
                }
              }
            }
          }
          .ant-btn {
            border: none;
            font-size: 1.6rem;
            font-family: $font-primary-medium;
            cursor: pointer;
            background-color: $color-primary;
            color: $color-white;
            transition: all 0.2s;
            height: 4rem;
            width: 10rem;
            margin-top: 3rem;
            margin-left: auto;
            display: block;
            &:hover,
            &:active,
            &:focus {
              background-color: $color-primary;
              color: $color-white;
              border: none;
            }
            span {
              line-height: normal;
            }
          }
        }
      }
    }
  }
}
</style>
