<template>
  <a-modal
    :visible="visible"
    :footer="null"
    @cancel="closeModal"
    width="60rem"
    class="contact-info-modal"
    :title="this.user.profile.full_name"
    v-if="isUser"
  >
    <div class="contact-info-modal-container">
      <div class="contact-info-modal-container__header">
        <h2 class="contact-info-modal-container__header--title">
          Contact info
        </h2>
        <div class="contact-info-modal-container__header--buttons">
          <button
            class="btn"
            @click="shareProfile()"
          >
            <img src="@/assets/icons/qr-code-icon.svg" class="scan-icon" />
          </button>
          <button
            class="btn"
            @click="userSettings()"
            v-if="isLoggedInUser"
          >
            <img src="@/assets/icons/edit-icon.svg" />
          </button>
        </div>
      </div>
      <div class="contact-info-modal-container__body">
        <div class="contact-info-modal-container__body--field">
          <i class="icon icon-account_circle"></i>
          <div class="details">
            <h5>Your Profile</h5>
            <a target="_black">
              {{ userProfileUrl }}
            </a>
          </div>
        </div>
        <div class="contact-info-modal-container__body--field">
          <img src="@/assets/icons/link-icon.png" alt="Icon" />
          <div class="details">
            <h5>Website</h5>
            <a target="_black">{{user.profile?.website_url}}</a>
          </div>
        </div>
        <div class="contact-info-modal-container__body--field">
          <img src="@/assets/icons/email-icon.png" alt="Icon" />
          <div class="details">
            <h5>Email</h5>
            <a>{{ user.email }}</a>
          </div>
        </div>
        <div class="contact-info-modal-container__body--field">
          <i class="icon icon-location"></i>
          <div class="details">
            <h5>Location</h5>
            <a>{{ user.profile.address }}</a>
          </div>
        </div>
      </div>
    </div>
  </a-modal>
  <ShareProfileQRCodeModal
    :visible="visibleShareProfileQRCodeModal"
    @closeModal="closeShareProfileModal"
  />
</template>

<script>
import ShareProfileQRCodeModal from "./ShareProfileQRCodeModal.vue";
export default {
  props: {
    visible: Boolean,
  },
  data() {
    return {
      visibleShareProfileQRCodeModal: false,
    };
  },
  components: {
    ShareProfileQRCodeModal
  },
  computed: {
    user() {
      return this.$store.getters["profile/getSelectedUserProfile"];
    },
    isLoggedInUser() {
      return this.$store.getters["profile/isLoggedinUser"];
    },
    userProfileUrl() {
      return `${process.env.VUE_APP_REDIRECTION_URL}/user/${this.user.profile.username}/`;
    },
    isUser() {
      return Object.keys(this.user).length > 0;
    },
  },
  methods: {
    closeModal() {
      this.$emit("close-modal");
    },
    userSettings() {
      this.$router.push(`/user/${this.user.profile.username}/settings`);
    },
    shareProfile() {
      this.visibleShareProfileQRCodeModal = true;
    },
    closeShareProfileModal() {
      this.visibleShareProfileQRCodeModal = false;
    },
  },
};
</script>

<style lang="scss">
.contact-info-modal {
  .ant-modal-content {
    .ant-modal-close {
      margin-right: 2rem !important;
      margin-top: 2rem !important;
      .ant-modal-close-x {
        .anticon {
          color: $color-black;
          opacity: 1;
        }
      }
    }
    .ant-modal-header {
      padding: 1.5rem 2rem;
      background-color: transparent;
      border-bottom: 1px solid $color-dark-grey-5;
      .ant-modal-title {
        color: $color-black;
        font-size: 2.4rem;
        font-family: $font-primary-medium;
        margin-bottom: 0;
        line-height: 2.8rem;
        margin-bottom: 0;
      }
    }
    .ant-modal-body {
      .contact-info-modal-container {
        padding: 2rem 2.6rem 4rem;
        &__header {
          margin-bottom: 2rem;
          display: flex;
          align-items: center;
          justify-content: space-between;
          &--title {
            color: $color-black;
            font-size: 2rem;
            font-family: $font-primary;
            line-height: 2.4rem;
            margin-bottom: 0;
          }
          &--buttons {
            display: flex;
            gap: 1rem;
            margin-right: -0.5rem;
            .btn {
              background-color: #e1e8ff;
              line-height: normal;
              border: none;
              outline: none;
              cursor: pointer;
              width: 3rem;
              height: 3rem;
              border-radius: 100% !important;
              img {
                width: 1.5rem;
              }
              .scan-icon {
                width: 1.2rem;
              }
            }
          }
        }
        &__body {
          display: flex;
          flex-direction: column;
          gap: 2rem;

          &--field {
            display: flex;
            align-items: flex-start;
            img {
              width: 2rem;
            }
            .icon {
              font-size: 2rem;
              color: $color-black;
            }
            .icon-location {
              font-size: 1.8rem;
            }
            .details {
              margin-left: 1rem;
              display: flex;
              flex-direction: column;
              gap: 0.2rem;
              h5 {
                color: $color-black;
                font-size: 1.6rem;
                font-family: $font-primary-medium;
                margin-bottom: 0;
                line-height: 1.8rem;
              }
              a {
                color: $color-primary;
                font-size: 1.4rem;
                font-family: $font-primary;
                margin-bottom: 0;
                line-height: 2rem;
              }
            }
          }
        }
      }
    }
  }
}
</style>
