import { mapActions } from 'vuex'
export default {
    data : ()=>({
        // selectedArchiveContentType: "articles",
    }),
    methods:{
        ...mapActions({
            fetchArchiveFoldersContentList: "archive/fetchArchiveFoldersContentList",
            emptyArchiveContentList: 'archive/emptyArchiveContentList',
            toggleArchiveContentLoader: 'archive/toggleArchiveContentLoader',
            toggleArchiveArticleLoader: 'archive/toggleArchiveArticleLoader',
            fetchArchiveSuggestedArticles: 'archive/fetchArchiveSuggestedArticles'
        }),

          async getSelectedContentTypeContentList({archiveFolderId, contentType, userId='', emptyPreviousContent=false, searchTopic=''}){
            if(emptyPreviousContent)
                this.emptyArchiveContentList()
            else {
                contentType === 'articles' || contentType === 'suggested'? this.toggleArchiveArticleLoader() : this.toggleArchiveContentLoader()
                contentType === 'suggested' ? await this.fetchArchiveSuggestedArticles(searchTopic) : await this.fetchArchiveFoldersContentList({ archiveFolderId, contentType, userId});
                contentType === 'articles' || contentType === 'suggested' ? this.toggleArchiveArticleLoader() : this.toggleArchiveContentLoader()
            }
        }
    }
}