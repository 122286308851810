<template>
  <div class="archive-management-content">
    <div class="archive-management-content__header">
      <div class="archive-management-content__header--title">
        <!-- <button @click="visibleFolderSideBar" class="side-bar-menu">
          <img src="@/assets/icons/menu.svg" />
        </button> -->
        <h1 class="title">
          <span v-if="selectedArchiveFolder?.id">{{ selectedArchiveFolder.name }}</span>
          <template v-else-if="archiveFolderList.length > 0 && !selectedArchiveFolder?.id">
            <a-skeleton
              class="title-skeleton"
              active
              :title="null"
              :paragraph="{
                rows: 1,
                width: ['10rem'],
              }"
            />
          </template>
        </h1>
      </div>
    </div>
    <div class="archive-management-content__body">
      <a-tabs v-model:activeKey="selectedArchiveContentType">
        <a-tab-pane key="articles">
          <template #tab>
            <div class="tab-name">
              <p>Articles</p>
            </div>
          </template>
          <div>
            <ArticleCollapseView :articleType="selectedArchiveContentType" collapseType="archive" :archiveFolderId="this.selectedArchiveFolder"/>
            <Pagination 
              v-if="!fetchContentLoader && folderContentList.length > 0"
              :totalPages="archiveContentTotalPages" 
              :results="folderContentList.length"
              :pageNumber="archiveContentCurrentPage"
              :pageSize="archiveArticlePageSize"
              :hasMoreContent="hasMoreArchiveContent"
              :checkContentLimit="true"
              @change-page="changePage"
              @move-first-page="changePage('move-first-page')"
              @move-last-page="changePage('move-last-page')"
            />
          </div>
        </a-tab-pane>
        <a-tab-pane key="videos">
          <template #tab>
            <div class="tab-name">
              <p>Videos</p>
            </div>
          </template>
          <div>
            <MediaSection 
              :archiveFolderId="this.selectedArchiveFolder"
              :isArchiveMedia="true"
            />
            <Pagination 
              v-if="!getFolderMediaContentLoader && folderContentList.length > 0"
              :totalPages="archiveContentTotalPages" 
              :results="folderContentList.length"
              :pageNumber="archiveContentCurrentPage"
              :pageSize="archiveVideoAndPodcastPageSize"
              :hasMoreContent="hasMoreArchiveContent"
              :checkContentLimit="true"
              @change-page="changePage"
              @move-first-page="changePage('move-first-page')"
              @move-last-page="changePage('move-last-page')"
            />
          </div>
        </a-tab-pane>
        <a-tab-pane key="podcast">
          <template #tab>
            <div class="tab-name">
              <p>Podcasts</p>
            </div>
          </template>
          <div>
            <MediaSection 
              :archiveFolderId="this.selectedArchiveFolder"
              :isArchiveMedia="true"
              :isVideoContent="false"
            />
            <Pagination 
              v-if="!getFolderMediaContentLoader && folderContentList.length > 0"
              :totalPages="archiveContentTotalPages" 
              :results="folderContentList.length"
              :pageNumber="archiveContentCurrentPage"
              :pageSize="archiveVideoAndPodcastPageSize"
              :hasMoreContent="hasMoreArchiveContent"
              :checkContentLimit="true"
              @change-page="changePage"
              @move-first-page="changePage('move-first-page')"
              @move-last-page="changePage('move-last-page')"
            />
          </div>
        </a-tab-pane>
        <a-tab-pane key="books">
          <template #tab>
            <div class="tab-name">
              <p>Books</p>
            </div>
          </template>
          <div>
            <MediaSection 
              :archiveFolderId="this.selectedArchiveFolder"
              :isArchiveMedia="true"
              :isBookContent="true"
              :isVideoContent="false"
            />
            <Pagination 
              v-if="!getFolderMediaContentLoader && folderContentList.length > 0"
              :totalPages="archiveContentTotalPages" 
              :results="folderContentList.length"
              :pageNumber="archiveContentCurrentPage"
              :pageSize="archiveVideoAndPodcastPageSize"
              :hasMoreContent="hasMoreArchiveContent"
              :checkContentLimit="true"
              @change-page="changePage"
              @move-first-page="changePage('move-first-page')"
              @move-last-page="changePage('move-last-page')"
            />
          </div>
        </a-tab-pane>
        <a-tab-pane key="suggested">
          <template #tab>
            <div class="tab-name">
              <p>Suggested Articles By Glocal</p>
            </div>
          </template>
          <div>
            <ArticleCollapseView :articleType="selectedArchiveContentType" collapseType="archive"/>
            <Pagination 
              v-if="!getFolderMediaContentLoader && folderContentList.length > 0"
              :totalPages="archiveContentTotalPages" 
              :results="folderContentList.length"
              :pageNumber="archiveContentCurrentPage"
              :pageSize="archiveVideoAndPodcastPageSize"
              :hasMoreContent="hasMoreArchiveContent"
              :checkContentLimit="true"
              @change-page="changePage"
              @move-first-page="changePage('move-first-page')"
              @move-last-page="changePage('move-last-page')"
            />
          </div>
        </a-tab-pane>
      </a-tabs>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import archiveMixin from "../../../mixins/archive.mixin";
import MediaSection from "../../GlocalFolders/MediaSection.vue";
import Pagination from "../../BaseComponents/CustomPagination.vue"
import ArticleCollapseView from "../../GlocalFolders/ArticleCollapseView.vue";

export default {
  mixins: [archiveMixin],
  data() {
    return {
      activeIndex: null,
      selectedArchiveContentType: "articles",
    };
  },
  components: {
    MediaSection,
    Pagination,
    ArticleCollapseView,
  },
  watch: {
    selectedArchiveContentType(val) {
      this.setActiveArchiveContentType(val)
      if(this.archiveContentCurrentPage > 1){
        //Reset the pagination whenever switching between content tabs
        this.updateArchiveContentCurrentPage('move-first-page')  // updating pagination triggers the fetching of articles
      }
      else{
        // if pagination is already set to 1 it will not trigger the watch function of over current page thus fetching the content manaully
        if (this.selectedArchiveFolder?.id && val !== 'suggested'){
            this.getSelectedContentTypeContentList({
              archiveFolderId: this.selectedArchiveFolder?.id,
              contentType: val,
              userId: this.selectedUser.id
            })
        }
        else if (this.selectedArchiveFolder?.id && val === 'suggested'){
          this.getSelectedContentTypeContentList({
              contentType: val,
              searchTopic: this.selectedArchiveFolder?.name,
            })
        }
        
      }
    },
    archiveContentCurrentPage(){
      // whenever the pagination changes fetch a new content accordingly
      this.getSelectedContentTypeContentList({
        archiveFolderId: this.selectedArchiveFolder?.id,
        contentType: this.selectedArchiveContentType,
        userId: this.selectedUser.id
      })
    },
    folderContentList(contentList){
      if (contentList.length === 0 ){
        // if all the content of the current page has been deleted and it has articles on other pages fetch articles again with updated pagination
        if (this.hasMoreArchiveContent)
          this.getSelectedContentTypeContentList({
            archiveFolderId: this.selectedArchiveFolder?.id,
            contentType: this.selectedArchiveContentType,
            userId: this.selectedUser.id
          })
        else if (!this.hasMoreArchiveContent){
          if(this.archiveContentCurrentPage > 1){
            this.updateArchiveContentCurrentPage('prev')            
            this.getSelectedContentTypeContentList({
                archiveFolderId: this.selectedArchiveFolder?.id,
                contentType: this.selectedArchiveContentType,
                userId: this.selectedUser.id
            })
          }
        }
      }
      else if( contentList.length > 0 ){
        /** 
         * If a content is deleted it and there is more content on other pages then fetch the content with updated pagination
        */
        const pageSize = this.selectedArchiveContentType === 'articles' ? this.archiveArticlePageSize : this.archiveVideoAndPodcastPageSize
        if(this.hasMoreArchiveContent && contentList.length < pageSize){
            this.getSelectedContentTypeContentList({
            archiveFolderId: this.selectedArchiveFolder?.id,
            contentType: this.selectedArchiveContentType,
            userId: this.selectedUser.id
        })
        }
      }
    },
    selectedArchiveFolder(){
      this.selectedArchiveContentType = 'articles'
    }
    
  },
  computed: {
    ...mapGetters({
      selectedArchiveFolder: "archive/selectedArchiveFolder",
      folderContentList: "archive/folderContentList",
      fetchContentLoader: 'archive/fetchContentLoader',
      archiveContentTotalCount :'archive/archiveContentTotalCount',
      archiveContentCurrentPage: 'archive/archiveContentCurrentPage',
      archiveArticlePageSize: 'archive/archiveArticlePageSize',
      archiveVideoAndPodcastPageSize: 'archive/archiveVideoAndPodcastPageSize',
      getFolderMediaContentLoader:"folders/getFolderMediaContentLoader",
      selectedUser: 'profile/selectedUser',
      archiveFolderList: "archive/archiveFolderList",
      hasMoreArchiveContent : 'archive/hasMoreArchiveContent'
    }),
    archiveContentTotalPages(){
      return this.selectedArchiveContentType === 'articles' ? Math.round(this.archiveContentTotalCount/this.archiveArticlePageSize) : Math.round(this.archiveContentTotalCount/this.archiveVideoAndPodcastPageSize)
    }
  },
  methods: {
    ...mapActions({
      setActiveArchiveContentType: 'archive/setActiveArchiveContentType',
      updateArchiveContentCurrentPage: 'archive/updateArchiveContentCurrentPage',
      fetchArchiveSuggestedArticles: 'archive/fetchArchiveSuggestedArticles'
    }),
    changePage(pageAction){
      this.updateArchiveContentCurrentPage(pageAction)
    }
  },
};
</script>

<style lang="scss">
.archive-management-content {
  margin: 0 2.3rem;
  &__header {
    margin-bottom: 4.4rem;
    padding-top: 0.5rem;
    &--title {
      display: flex;
      align-items: center;
      width: fit-content;
      .side-bar-menu {
        display: none;
        margin-right: 2.5rem;
        line-height: normal;
        padding: 0;
        height: fit-content;
        width: fit-content;
        border: none;
        background-color: transparent;
        color: $color-black;
        outline: none;
        cursor: pointer;
        margin-top: 0.5rem;
        @include respond(tab-port) {
          display: block;
        }
        img {
          width: 3.2rem;
        }
      }
      .title {
        font-size: 2rem;
        font-family: $font-primary-medium;
        line-height: 2.3rem;
        color: $color-black;
        margin-bottom: 0;
      }
      .title-skeleton {
        .ant-skeleton-content {
          .ant-skeleton-paragraph {
            margin: 0;
            li {
              height: 2.4rem;
            }
          }
        }
      }
    }
  }
  &__body {
    .ant-tabs {
      .ant-tabs-bar {
        border-bottom: 1px solid $color-dark-grey-5;
        margin: 0;
        margin-bottom: 2rem;

        .ant-tabs-nav-container {
          padding: 0 0 0 2.6rem;

          .ant-tabs-tab-prev,
          .ant-tabs-tab-next {
            display: none !important;
          }

          .ant-tabs-nav-wrap {
            .ant-tabs-nav-scroll {
              overflow-x: auto;

              @include respond(phone-x-small) {
                &::-webkit-scrollbar {
                  display: none !important;
                }
              }
              &::-webkit-scrollbar {
                height: 0.5rem;
                width: 0.5rem;
              }

              &::-webkit-scrollbar-track {
                background-color: transparent;
                border-radius: 0.8rem;
                height: 0.5rem;
                width: 0.5rem;
              }

              &::-webkit-scrollbar-thumb {
                background-color: $color-primary;
                border-radius: 0.8rem;
              }

              .ant-tabs-nav {
                width: 100%;
                transform: none !important;
                .ant-tabs-tab {
                  background-color: $color-light-grey-3;
                  padding: 0.8rem 2rem;
                  border-radius: 100px;
                  margin-right: 1.6rem;
                  border-top-right-radius: 1rem !important;
                  border-top-left-radius: 1rem !important;
                  border-bottom-left-radius: 0 !important;
                  border-bottom-right-radius: 0 !important;
                  height: 4.1rem;
                  margin-bottom: -0.1rem;
                  display: flex;
                  align-items: center;
                  .tab-name {
                    display: flex;
                    align-items: center;

                    p {
                      font-size: 1.6rem;
                      line-height: 2;
                      font-family: $font-primary-medium;
                      color: $color-dark-grey-6;
                      margin: 0;
                    }

                    svg {
                      margin-right: 1rem;
                      width: 1.5rem;
                    }

                    svg g path {
                      fill: $color-primary;
                    }
                    .dropdown {
                      margin-left: 0.5rem;
                      .icon {
                        font-size: 1.2rem;
                        color: $color-dark-grey-6;
                      }
                    }
                  }

                  &:last-child {
                    margin-right: 0;
                  }
                }

                .ant-tabs-tab-active {
                  color: $color-white;
                  background-color: $color-primary;
                  font-family: $font-primary-medium;
                  text-shadow: none;

                  .tab-name {
                    p {
                      color: $color-white;
                    }

                    svg g path,
                    svg path {
                      fill: $color-white;
                    }

                    .dropdown {
                      .icon {
                        color: $color-white;
                      }
                    }
                  }
                }

                .ant-tabs-ink-bar {
                  display: none !important;
                }
              }
              .ant-tabs-nav > div {
                display: flex;
              }
            }
          }
        }
      }

      .ant-tabs-top-content {
        .ant-tabs-tabpane {
          height: 100%;
          margin-bottom: 8rem;
          overflow: hidden;
          min-height: 40rem;
          padding: 0 2.6rem !important;
          .article-card-new {
            &__footer {
              .the-horizontal-reactions-bar {
                border: none;
              }
              &--reactions {
                border-left: 1px solid $color-dark-grey-5;
              } 
            }
          }
          .message {
            padding-top: 4rem;
            p {
              font-size: 1.6rem;
              font-family: $font-primary;
              margin-bottom: 0;
              line-height: 2.3rem;
              color: $color-black;
              text-align: center;
            }
          }
          .pagination-container {
            margin-top: auto;
            &__pagination {
              bottom: 3rem;
              width: calc(100% - 4rem);
              align-items: baseline;
              position: absolute;
              @include respond(tab-port) {
                justify-content: center;
              }
            }
          }
        }
      }
    }
  }
}
</style>
